(function() {
    'use strict';

    angular.module('uabEnvironment', [
        'ui.router',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabEnvironment').controller('EnvironmentController', EnvironmentController);

    EnvironmentController.$inject = [
        'EnvironmentService'
    ];

    function EnvironmentController(
        EnvironmentService
    ) {
        var EnvironmentController = this;

        EnvironmentController.get = get;
        function get(name) {
            return EnvironmentService.get(name);
        }

        EnvironmentController.getAll = getAll;
        function getAll() {
            return EnvironmentService.getAll();
        }

        EnvironmentController.remove = remove;
        function remove(key) {
            return EnvironmentService.remove(key);
        }

        EnvironmentController.set = set;
        function set(key, value) {
            return EnvironmentService.set(key, value);
        }

        EnvironmentController.setAll = setAll;
        function setAll(variables) {
            return EnvironmentService.set(variables);
        }

        EnvironmentController.reset = reset;
        function reset() {

        }

        EnvironmentController.init = init;
        function init() {
            EnvironmentController.reset();
        }

        EnvironmentController.init();
    }
})();
(function() {
    'use strict';

    angular.module('uabEnvironment').directive('environment', environment);

    function environment() {
        return {
            controller:   'EnvironmentController',
            controllerAs: 'environmentCtrl',
            restrict:     'E',
            template:'<div><p>active_user_id: {{ environmentCtrl.get(\'active_user_id\') }}</p><p>name: {{ environmentCtrl.get(\'name\') }}</p><p>project_id: {{ environmentCtrl.get(\'project_id\') }}</p><p>user_id: {{ environmentCtrl.get(\'user_id\') }}</p><p>boolean: {{ environmentCtrl.get(\'boolean\') }}</p><p>{{ environmentCtrl.getAll() }}</p><div><label>Key: <input type="text" data-ng-model="key"></label> <label>Value <input type="text" data-ng-model="value"></label> <input type="button" value="Set" data-ng-click="environmentCtrl.set(key, value)"></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('uabEnvironment').service('EnvironmentService', EnvironmentService);

    EnvironmentService.$inject = [
        'DefaultVariableService',
        'ENVIRONMENT',
        '$stateParams'
    ];

    function EnvironmentService(
        DefaultVariableService,
        ENVIRONMENT,
        $stateParams
    ) {
        var EnvironmentService = this;

        EnvironmentService.get = get;
        function get(name, suggestion) {
            var returnVar = false;
            if (DefaultVariableService.isDefined(suggestion)) {
                returnVar = suggestion;
            }

            if (DefaultVariableService.isDefined(EnvironmentService.list[name])) {
                returnVar = EnvironmentService.list[name];
            } else if (DefaultVariableService.isDefined(ENVIRONMENT.debug) && DefaultVariableService.isDefined(ENVIRONMENT.debug[name])) {
                returnVar = ENVIRONMENT.debug[name];
            } else if (DefaultVariableService.isDefined(ENVIRONMENT[name])) {
                returnVar = ENVIRONMENT[name];
            } else {
                if (DefaultVariableService.isDefined($stateParams[name]) && $stateParams[name] !== '') {
                    returnVar = $stateParams[name];
                } else {
                    name = DefaultVariableService.toCamelCase(name);

                    if (DefaultVariableService.isDefined($stateParams[name]) && $stateParams[name] !== '') {
                        returnVar = $stateParams[name];
                    }
                }
            }
            
            return returnVar;
        }

        EnvironmentService.getAll = getAll;
        function getAll() {
            var keys = Object.keys(ENVIRONMENT.debug);
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];

                if (!EnvironmentService.has(key)) {
                    var value = ENVIRONMENT.debug[key];

                    EnvironmentService.set(key, value);
                }
            }

            return EnvironmentService.list;
        }

        EnvironmentService.has = has;
        function has(key) {
            return DefaultVariableService.isDefined(
                EnvironmentService.list[key]
            );
        }

        EnvironmentService.remove = remove;
        function remove(key) {
            if (EnvironmentService.has(key)) {
                delete EnvironmentService.list[key];
            }
        }

        EnvironmentService.removeAll = removeAll;
        function removeAll() {
            EnvironmentService.list = {};
        }

        EnvironmentService.set = set;
        function set(key, value) {
            EnvironmentService.list[key] = value;
        }

        EnvironmentService.setAll = setAll;
        function setAll(variables) {
            var keys = Object.keys(variables);

            var keysLength = keys.length;

            for (var i = 0; i < keysLength; i++) {
                var key = keys[i];
                var value = variables[key];

                EnvironmentService.set(key, value);
            }

            return EnvironmentService.list;
        }

        EnvironmentService.reset = reset;
        function reset() {
            EnvironmentService.list = {};
        }

        EnvironmentService.reset();

        return EnvironmentService;
    }
})();
